<template>
  <div>
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label>#สมาชิก</label>
            <input
              type="text"
              class="form-control w-auto"
              v-model="overAll.userAffiliate.userId"
              disabled
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>จำนวนสมาชิกแนะนำ</label>
            <input
              type="text"
              class="form-control w-auto"
              v-model="overAll.countMember"
              disabled
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>ดูทั้งหมด</label>
            <input
              type="text"
              class="form-control w-auto"
              v-model="overAll.userAffiliate.view"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label>รายได้ปัจจุบัน</label>
            <input
              type="text"
              class="form-control w-auto"
              v-model="overAll.currentAfIncome"
              disabled
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>รายได้ทั้งหมด</label>
            <input
              type="text"
              class="form-control w-auto"
              v-model="overAll.userAffiliate.earningSum"
              disabled
            />
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>แทงทั้งหมด</label>
            <input
              type="text"
              class="form-control w-auto"
              v-model="overAll.sumBet"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 my-2 border-bottom"></div>
    <div class="d-flex flex-column mb-2">
      <span class="mr-2"><i class="fas fa-calendar-alt"></i> เลือกเดือน</span>
      <month-picker-input
        class="w-100"
        :months="months"
        :default-month="defaultMonth"
        :default-year="defaultYear"
        :input-pre-filled="true"
        @input="loadData"
      ></month-picker-input>
    </div>
    <loading v-if="isLoading" />
    <div v-if="!isLoading" class="text-black">
      <div class="d-flex flex-row mb-2 w-100">
        <div class="table-secondary border rounded-left p-2 d-inline w-50 mr-0">
          วันที่
        </div>
        <div
          class="
            bg-white
            border
            text-right text-sm-right text-md-left
            p-2
            d-inline
            w-25
            ml-0
          "
        >
          <span class="thb">หวย</span>
        </div>
        <div
          class="
            bg-white
            border
            rounded-right
            text-right text-sm-right text-md-left
            p-2
            d-inline
            w-25
            ml-0
          "
        >
          <span class="thb">สล็อต/คาสิโน</span>
        </div>
      </div>
      <div
        v-for="(item, index) in datas"
        :key="index"
        class="d-flex flex-row mb-2 w-100"
      >
        <div class="table-secondary border rounded-left p-2 d-inline w-50 mr-0">
          {{ item.dateTH }}
        </div>
        <div
          class="
            bg-white
            border
            text-right text-sm-right text-md-left
            p-2
            d-inline
            w-25
            ml-0
          "
        >
          <span class="thb"
            >฿ {{ util.formatMoney(item.sumAffiliateAmount) }}</span
          >
        </div>
        <div
          class="
            bg-white
            border
            rounded-right
            text-right text-sm-right text-md-left
            p-2
            d-inline
            w-25
            ml-0
          "
        >
          <span class="thb"
            >฿ {{ util.formatMoney(item.sumGameAffiliateAmount) }}</span
          >
        </div>
      </div>
      <div class="d-flex flex-row mb-2 w-100">
        <div class="table-secondary border rounded-left p-2 d-inline w-50 mr-0">
          ยอดรวม (เดือน)
        </div>
        <div
          class="
            bg-white
            border border-left-0
            text-right text-sm-right text-md-left
            p-2
            d-inline
            w-25
            ml-0
          "
        >
          <span class="thb">฿ {{ util.formatMoney(sum) }}</span>
        </div>
        <div
          class="
            bg-white
            border border-left-0
            rounded-right
            text-right text-sm-right text-md-left
            p-2
            d-inline
            w-25
            ml-0
          "
        >
          <span class="thb">฿ {{ util.formatMoney(sumGame) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MonthPickerInput } from "vue-month-picker"

export default {
  components: {
    MonthPickerInput,
  },
  data() {
    return {
      isLoading: true,
      defaultMonth: parseInt(this.$date().format("MM")),
      defaultYear: parseInt(this.$date().format("YYYY")),
      months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      datas: [],
      sum: 0,
      sumGame: 0,
      overAll: {
        userAffiliate: {},
      },
    }
  },
  methods: {
    async loadData(date = null) {
      this.isLoading = true
      const month = date
        ? `${date.year}-${date.monthIndex.toString().padStart(2, "0")}`
        : this.$date().format("YYYY-MM")

      try {
        const res = await this.axios({
          method: "get",
          url: "users/affiliate/statement/" + this.$route.params.userId,
          params: {
            month,
          },
        })

        const data = res.data.data
        const dt = this.$date(month, "YYYY-MM")
        const startOfMonth = parseInt(dt.startOf("month").format("DD"))
        const endOfMonth = parseInt(dt.endOf("month").format("DD"))
        const monthNumber = parseInt(dt.format("MM"))
        const datas = []
        let sum = 0
        let sumGame = 0
        for (let i = startOfMonth; i <= endOfMonth; i++) {
          const item = data?.find((item) => item.dayNumber === i)
          const sumAffiliateAmount = item ? item.sumAffiliateAmount : 0
          const sumGameAffiliateAmount = item ? item.sumGameAffiliateAmount : 0

          sum += parseFloat(sumAffiliateAmount)
          sumGame += parseFloat(sumGameAffiliateAmount)

          datas.push({
            dateTH: `${i} ${this.months[monthNumber - 1]} ${dt.format("YYYY")}`,
            sumAffiliateAmount: sumAffiliateAmount,
            sumGameAffiliateAmount: sumGameAffiliateAmount,
          })
        }
        this.datas = datas

        this.sum = sum
        this.sumGame = sumGame
        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.axios({
      method: "get",
      url: "users/affiliate/over-all/" + this.$route.params.userId,
    })
      .then((res) => {
        let data = res.data.data

        data.currentAfIncome = `฿${this.util.formatMoney(data.currentAfIncome)}`
        data.sumBet = `฿${this.util.formatMoney(data.sumBet)}`
        data.userAffiliate.earningSum = `฿${this.util.formatMoney(
          data.userAffiliate.earningSum
        )}`

        this.overAll = data
      })
      .catch((err) => {
        console.log(err)
        this.swal.swalError()
      })

    this.loadData()
  },
}
</script>

<style>
.month-picker__container {
  background: white;
  color: black;
}
</style>